const { httpUtils } = require("../util/httpUtils");
const LOC_AUTH = process.env.NEXT_PUBLIC_LOC_KEY;

const sendMail = async data => {
   const res = await httpUtils({
      method: "post",
      url: `https://playo.io/api/web/contact/`,
      body: data
   });
   return res;
};

const getLocationFromIp = async () => {
   const res = await httpUtils({
      method: "GET",
      url: `https://ipwhois.pro/?key=${LOC_AUTH}`
   });

   return res;
};

export const homepageApi = {
   getLocationFromIp,
   sendMail
};
